<template>
    <v-container fluid class="my-10">
        <v-row>

            <v-dialog v-model="showDialogCrear" persistent max-width="800px">
                <v-card dense>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="cerrarSolicitar">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Crear Reporte Bajo Demanda</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col cols="10">
                                <date-range-picker ref="dateRange" v-model="selectedDatesIn"></date-range-picker>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="rfcSearchIn" @change="cambio = true" counter="0" label="RFC"
                                    maxlength="13" class="my-1"></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" md="6">
                                <v-select :items="itemsOrigen" v-model="contexto" label="Contexto" outlined></v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field v-model="serieIn" @change="cambio = true" counter="0" label="Serie"
                                    maxlength="30" class="my-1 min-width-control"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field v-model="folioIn" @change="cambio = true" counter="0" label="Folio"
                                    maxlength="30" class="my-1 min-width-control"></v-text-field>
                            </v-col>

                            <v-col class="d-flex" cols="12" sm="6">
                                <v-select class="min-width-control-xl" :items="itemsSelect" v-model="tipoDocMIn"
                                    label="Tipo de Comprobante" outlined multiple></v-select>
                            </v-col>


                            <v-col cols="12" md="6">
                                <v-select class="min-width-control-xl" v-model="retencionesFiltroIn"
                                    :items="retencionesColection" label="Impuestos Retenidos Federales" multiple chips
                                    outlined></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select class="min-width-control-xl" v-model="trasladosFiltroIn"
                                    :items="trasladosColection" label="Impuestos Traslados Federales" multiple chips
                                    outlined></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select class="min-width-control-xl" :items="itemsTF" v-model="tipoTFIn"
                                    label="Tipo de Factor" outlined>
                                </v-select>
                            </v-col>

                            <v-col cols="12" md="6">
                                <v-select class="min-width-control-xl" :items="itemsRegimenes" item-text="name"
                                    item-value="codigo" v-model="regimenesEIn" label="Regímenes Fiscales Emisor"
                                    outlined></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select class="min-width-control-xl" :items="itemsRegimenes" item-text="name"
                                    item-value="codigo" v-model="regimenesRIn" label="Regímenes Fiscales Receptor"
                                    outlined></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select class="min-width-control-xl" :items="itemsComplementosCFDI" item-text="name"
                                    item-value="codigo" v-model="complementosCFDIIn" label="Complementos" multiple chips
                                    outlined></v-select>
                            </v-col>

                            <v-col md="8" justify="center">
                                <v-text-field v-model="descripcionIn" counter="0" label="Descripción*" class="my-1"
                                    maxlength="100">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider class="mt-12"></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="requestItems" text large :disabled="!showCreateButton">Solicitar
                            Reporte
                        </v-btn>
                        <v-btn color="danger" text @click="cerrarSolicitar">Cerrar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="showDialogEliminarReport" persistent max-width="900px">
                <v-card dense>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="showDialogEliminarReport = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Confirmación Eliminar Reporte:
                            {{ this.itemDesc }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <br />
                        <h3>Este reporte sera eliminado permanentemente</h3>
                        <h3>¿Está seguro de continuar con esta acción?</h3>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="EliminarReporte">Confirmar</v-btn>
                        <v-btn color="danger" text @click="showDialogEliminarReport = false">Cerrar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <v-col cols="12">
                <h3>Conciliatorio No Cancelado con Desglose de Impuestos en Columnas</h3>
            </v-col>
            <v-col cols="12">
                <v-divider></v-divider>
            </v-col>
            <v-col cols="11">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Reportes Solicitados</v-toolbar-title>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="ObtenerReportes" icon v-on="on">
                                    <v-icon>mdi-cached</v-icon>
                                </v-btn>
                            </template>
                            <span>Refrescar Tabla</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">
                                <v-btn icon dark v-show="mostrarDescargarSeleccionado" @click="descargarMultiple"
                                    v-on="on" class="ml-auto mr-3">
                                    <v-icon>mdi-download-multiple-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Descargar Seleccionados</span>
                        </v-tooltip>
                        <v-btn @click="showDialogCrear = true" large color="second">
                            <v-icon>mdi-plus</v-icon>Solicitar Reporte
                        </v-btn>
                    </v-toolbar>

                    <v-row>
                        <v-col cols="12">
                            <v-simple-table height="600" fixed-header dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>
                                                <v-checkbox v-model="allSelected" @change="toggleSelectAll"
                                                    color="primary" hide-details></v-checkbox>
                                            </th>
                                            <th>Descripción</th>
                                            <th>Fecha Solicitud</th>
                                            <th>Contexto</th>
                                            <th>Fecha Inicial</th>
                                            <th>Fecha Final</th>
                                            <th>RFC</th>
                                            <th>Tipo Comprobante</th>
                                            <th>Mensaje</th>
                                            <th>Disponible</th>
                                            <th>Resultado</th>

                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in itemsGrandes" :key="item.id" class="altura">
                                            <td>
                                                <v-checkbox v-model="selectedItemsDescarga" :value="item"
                                                    color="primary" hide-details></v-checkbox>
                                            </td>

                                            <td>
                                                {{ item.description }}
                                            </td>
                                            <td>
                                                {{ item.requestDate }}
                                            </td>
                                            <td>
                                                {{
                                                    item.commands.context == "E"
                                                        ? "Emitido"
                                                        : "Recibido"
                                                }}
                                            </td>
                                            <td>
                                                {{ item.commands.dateInit }}
                                            </td>
                                            <td>
                                                {{ item.commands.dateEnd }}
                                            </td>
                                            <td>
                                                {{ item.commands.taxid }}
                                            </td>
                                            <td>
                                                {{ item.commands.docType }}
                                            </td>
                                            <td>
                                                {{ item.message }}
                                            </td>
                                            <td>
                                                {{ item.ready ? "Si" : "No" }}
                                            </td>
                                            <td>
                                                {{
                                                    item.ready
                                                        ? item.result
                                                            ? "Exitoso"
                                                            : "Fracasado"
                                                        : "Procesando"
                                                }}
                                            </td>
                                            <td class="pa-0 md-0">
                                                <v-tooltip left v-if="item.ready && item.result">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn @click="DescargarReporte(item.id)" icon color="#4aa832"
                                                            v-on="on">
                                                            <v-icon>mdi-download</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Descargar Reporte</span>
                                                </v-tooltip>
                                            </td>
                                            <td class="pa-0 md-0">
                                                <v-tooltip left v-if="item.ready">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn @click="ConfirmarEliminarReporte(item)" icon color="red"
                                                            v-on="on">
                                                            <v-icon>mdi-delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Eliminar Reporte</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import { JSONToCSV } from "../../scripts/helpers.js";
import DateRangePicker from '../Componentes/DateRangePicker.vue';

export default {
    name: "DesgloseImpuestos",
    components: {
        DateRangePicker
    },

    data: () => ({
        selectedItemsDescarga: [],
        allSelected: false,

        selectedDatesIn: [],
        showDialogEliminarReport: false,
        showDialogCrear: false,
        itemsGrandes: [],
        itemDesc: "",
        modal: false,


        rfcSearchIn: "",
        serieIn: "",
        folioIn: "",
        regimenesRIn: "0",
        regimenesEIn: "0",
        complementosCFDIIn: [],
        tipoTFIn: "Seleccionar Todos",
        trasladosFiltroIn: [],
        trasladosColection: ['IVA (002)', 'IEPS(003)'],
        retencionesFiltroIn: [],
        retencionesColection: ['ISR(001)', 'IVA (002)', 'IEPS(003)'],
        tipoDocMIn: [],
        itemsSelect: ["Ingreso", "Egreso", "Traslado", "Pago"],
        itemsTF: ["Seleccionar Todos", "Tasa", "Cuota", "Exento"],
        itemsComplementosCFDI: helpers.ComplementosCFDI,

        nombre: "",
        descripcionIn: "",
        contexto: "Emitidos"
    }),
    created() {
        this.ObtenerReportes();
    },

    methods: {
        cerrarSolicitar() {
            this.nombre = "";
            this.descripcionIn = "";
            this.tipoDocMIn = [];
            this.selectedDatesIn = [];
            this.$refs.dateRange.limpiarFechas();
            this.rfcSearchIn = "";
            this.descripcionIn = "";
            this.regimenesRIn = "0";
            this.regimenesEIn = "0";
            this.tipoTFIn = "Seleccionar Todos";
            this.trasladosFiltroIn = [];
            this.retencionesFiltroIn = [];

            this.contexto = "Emitidos";
            this.rfcSearch = "";
            this.showDialogCrear = false;
        },

        requestItems() {
            this.showDialogCrear = false;
            var jsonData = {
                description: this.descripcionIn,
                dateInit: this.selectedDatesIn[0],
                dateEnd: this.selectedDatesIn[1],
                context: this.contexto.substring(0, 1),
                taxid: this.rfcSearchIn,
                token: this.$store.state.token,
                docType: this.getFormatTipoComprobanteIn(),
                onlyCancelled: this.esCancelado === 'true',
                dateTs: this.dateTs,
                retenciones: this.obtenerRetencionesIn(),
                traslados: this.obtenerTrasladosIn(),
                tipo_factor: this.obtenerTipoFactorIn(),
                emisor_regimen_fiscal: this.obtenerRegimenesEIn(),
                receptor_regimen_fiscal: this.obtenerRegimenesRIn(),
                serie: this.serieIn,
                folio: this.folioIn,
                complementos: this.obtenerComplementosIn(),
                tipo: 'REPORT-CFDI-CONCILIATORIO-DESGLOSE-V3'

            };
            service
                .api()
                .ReporteConciliatorioGrande(jsonData)
                .then((response) => {
                    if (response.result) {

                        this.$store.commit("setAlertError", false);
                        this.$store.commit("setAlertOk", true);
                        this.$store.commit("setAlertMessage", "Reporte Solicitado Correctamente");

                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                    this.selectedDatesIn = [];
                    this.$refs.dateRange.limpiarFechas();
                    this.rfcSearchIn = "";
                    this.descripcionIn = "";
                    this.regimenesRIn = "0";
                    this.regimenesEIn = "0";
                    this.tipoTFIn = "Seleccionar Todos";
                    this.trasladosFiltroIn = [];
                    this.retencionesFiltroIn = [];
                    this.tipoDocMIn = [];

                });


        },
        ObtenerReportes() {
            var jsonData = {
                token: this.$store.state.token,
                tipo: 'REPORT-CFDI-CONCILIATORIO-DESGLOSE-V3'
            };

            service
                .api()
                .ReporteConciliatorioRetrieveGrandes(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.itemsGrandes = JSON.parse(response.payload);

                    } else {

                        this.itemsGrandes = [];

                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        ConfirmarEliminarReporte(item) {
            this.eliminarId = item.id;
            this.itemDesc = item.description;
            this.showDialogEliminarReport = true;
        },
        EliminarReporte() {
            var jsonData = {
                id: this.eliminarId,
                token: this.$store.state.token,
                tipo: 'REPORT-CFDI-CONCILIATORIO-DESGLOSE-V3'
            };
            this.showDialogEliminarReport = false;
            service
                .api()
                .ReporteConciliatorioDeleteReport(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setAlertError", false);
                        this.$store.commit("setAlertOk", true);
                        this.$store.commit("setAlertMessage", "Reporte Eliminado Correctamente");
                        this.ObtenerReportes();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },


        DescargarReporte(itemId) {
            return new Promise((resolve, reject) => {
                const jsonData = {
                    id: itemId,
                    token: this.$store.state.token,
                };

                service
                    .api()
                    .ReporteConciliatorioRetrieveReport(jsonData)
                    .then((response) => {
                        if (response.result) {
                            this.$store.commit("setOverlay", false);
                            this.retrieveReporte(response.payload);
                            resolve(response.payload); // Resolver con el payload
                        } else {
                            this.$store.commit("setAlertOk", false);
                            this.$store.commit("setAlertError", true);
                            this.$store.commit("setAlertMessage", response.errorMessage);
                            reject(new Error(response.errorMessage)); // Rechazar con mensaje de error
                        }
                    })
                    .catch((error) => {
                        reject(error); // Rechazar si ocurre un error en el servicio
                    });
            });
        },
        descargarMultiple() {
            this.descargarTodosEnParalelo(this.selectedItemsDescarga);

        },
        async descargarTodosEnParalelo(items) {
            try {
                const promesas = items.map((item) => this.DescargarReporte(item.id));
                await Promise.all(promesas);
                console.log("Todas las descargas han finalizado.");
            } catch (error) {
                console.error("Error en una o más descargas:", error);
            }
        },
        toggleSelectAll() {
            if (this.allSelected) {
                this.selectedItemsDescarga = [...this.itemsGrandes];
            } else {
                this.selectedItemsDescarga = [];
            }
        },




        retrieveReporte(ruta) {
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(ruta, "_blank");
            } else {
                helpers.openNewBackgroundTab(ruta);
            }
            setTimeout(() => {
                this.$store.commit("setOverlay", false);
            }, 1000);
        },
        obtenerRegimenesEIn() {

            if (this.regimenesEIn == "0")
                return "";
            else
                return this.regimenesEIn;
        },
        obtenerRegimenesRIn() {

            if (this.regimenesRIn == "0")
                return "";
            else
                return this.regimenesRIn;
        },

        getFormatTipoComprobanteIn() {

            let tipoText = "";
            if (this.tipoDocMIn.length == 0) {
                tipoText = "";
            }
            else {
                tipoText = this.tipoDocMIn.map(texto => texto.charAt(0)).join(',');
            }
            return tipoText
        },
        obtenerRetencionesIn() {
            let filtroRet = "";
            if (this.retencionesFiltroIn.length != 3 && this.retencionesFiltroIn.length != 0) {
                let retConcat = this.retencionesFiltroIn.toString();
                let newData = [];
                if (retConcat.includes("1")) {
                    newData.push("001");
                }

                if (retConcat.includes("2")) {
                    newData.push("002");
                }

                if (retConcat.includes("3")) {
                    newData.push("003");
                }
                filtroRet = newData.join(",");
            }
            return filtroRet;
        },

        obtenerTrasladosIn() {
            let filtroTras = "";
            if (this.trasladosFiltroIn.length != 2 && this.trasladosFiltroIn.length != 0) {
                let trasConcat = this.trasladosFiltroIn.toString();
                let newData = [];

                if (trasConcat.includes("2")) {
                    newData.push("002");
                }

                if (trasConcat.includes("3")) {
                    newData.push("003");
                }
                filtroTras = newData.join(",");
            }
            return filtroTras;
        },
        obtenerTipoFactorIn() {
            if (this.tipoTFIn == "Seleccionar Todos")
                return "";
            else
                return this.tipoTFIn;
        },
        obtenerComplementosIn() {
            let filtroComplemento = "";
            if (this.complementosCFDIIn.length > 0)
                filtroComplemento = this.complementosCFDIIn.join(",");
            return filtroComplemento;
        },
    },
    computed: {
        mostrarDescargarSeleccionado() {
            return this.selectedItemsDescarga.length > 0;
        },
        itemsRegimenes() {
            let itemsReg = helpers.RegimenesConcat;
            let nuevoObjeto = { codigo: "0", name: "Seleccionar Todos" };
            itemsReg.unshift(nuevoObjeto);
            return itemsReg;
        },
        showCreatedButton() {
            return this.rfcSearch.length >= 12 && this.rfcSearch.length <= 13;
        },
        showSolicitarReporte() {
            return this.descripcionIn.length > 3;
        },
        fechaInicial() {
            return helpers.getFechaLimite(false);
        },
        showCreateButton() {
            return this.selectedDatesIn.length > 1 && this.descripcionIn.length > 0;
        },
        allowIssued() {
            return this.$store.state.allowIssued === true;
        },
        allowReceived() {
            return this.$store.state.allowReceived === true;
        },
        itemsOrigen() {
            let elements = [];
            if (this.allowIssued)
                elements.push("Emitidos");
            if (this.allowReceived)
                elements.push("Recibidos");

            if (!this.allowIssued) {
                this.contexto = "Recibidos";
            }

            return elements;
        },
    },
};
</script>
<style scoped>
.altura {
    height: 40px;
}

.negro {
    font-weight: bold;
}

.ancho {
    width: 35px;
}

.anchoP {
    width: 100px;
}

.normal {
    font-weight: regular;
}

.tamPagina {
    font-weight: bold;
    font-size: 20px;
}
</style>