import Vue from "vue";
import VueRouter from "vue-router";


import ConciliatorioE from "../components/FilesConciliatorio/ConciliatorioEmitidos";
import ConciliatorioEA from "../components/FilesConciliatorio/ConciliatorioEmitidosAll";
import ConciliatorioECan from "../components/FilesConciliatorio/ConciliatorioEmitidosCancelados";
import ConciliatorioR from "../components/FilesConciliatorio/ConciliatorioRecibidos";
import ConciliatorioRA from "../components/FilesConciliatorio/ConciliatorioRecibidosAll";
import ConciliatorioRCan from "../components/FilesConciliatorio/ConciliatorioRecibidosCancelados";
import ConciliatorioCancelados from "../components/FilesConciliatorio/ConciliatorioCancelados";
import VigentesCancelados from "../components/FilesConciliatorio/VigentesCancelados";
import ConciliatorioPorFechaCancelacion from "../components/FilesConciliatorio/ConciliatorioPorFechaCancelacion";

import ConciliatorioResumen from "../components/FilesConciliatorio/Resumen";

import ConciliatorioBL from "../components/FilesConciliatorio/ConciliatorioBL";

import ConciliatorioAuto from "../components/FilesConciliatorio/ReporteAutofactura";

import ImpuestosLocales from "../components/FilesConciliatorio/ImpuestosLocales";
import DesgloseImpuestos from "../components/FilesConciliatorio/DesgloseImpuestos";
import DesgloseImpuestosColumnas from "../components/FilesConciliatorio/DesgloseImpuestosColumnas";

import ReporteNomina from "../components/Nomina/ReporteNomina";
import ReporteControlNomina from "../components/Nomina/ControlNomina";
import ReporteNominaCancelados from "../components/Nomina/ReporteNominaCancelados";
import ReporteNominaFechaCancelacion from "../components/Nomina/ReporteNominaFechaCancelacion";
import DetalleNomina from "../components/Nomina/DetalleNomina";
import DetalleNominaDesglose from "../components/Nomina/ReporteNominaDesglose.vue";
import DetalleNominaDesgloseV2 from "../components/Nomina/ReporteNominaDesgloseV2.vue";

import PaymentEx from "../components/FilesPayment/PaymentEx";
import PaymentNotFound from "../components/FilesPayment/PaymentNotFound";
import PaymentNotAllowed from "../components/FilesPayment/PaymetNotAllowed";
import PaymentRelCancelled from "../components/FilesPayment/PaymentRelCancelled.vue";

import BreakDownE from "../components/FilesBreakdown/BreakdownEmitidos";
import BreakDownR from "../components/FilesBreakdown/BreakdownRecibidos";

import Cashflow from "../components/FilesFinanciero/Cashflow";

import RelacionadosA from "../components/FilesRelacionados/RelacionadosAll";
import RelacionadosE from "../components/FilesRelacionados/RelacionadosEmitidos";
import RelacionadosR from "../components/FilesRelacionados/RelacionadosRecibidos";
import RelacionadosX from "../components/FilesRelacionados/Xray";

import IncomeE from "../components/FilesIncome/IncomeEmitidos";
import IncomeR from "../components/FilesIncome/IncomeRecibidos";
import IncomeA from "../components/FilesIncome/IncomeAll";

import AgeAnalysis from "../components/AgeAnalysis/AgeAnalysisReport";

import IvaSat from "../components/FilesConciliatorio/ConciliatorioIvaSat";

import BlackListReport from "../components/BlackList/BlackList69";
import BlackListBReport from "../components/BlackList/BlackList69B";
import BlackListResume from "../components/BlackList/BlackListResume";

import PaymentMethodVsForm from "../components/Mismatch/PaymentMethodVsForm.vue";

import PaymentVsCfdi from "../components/FilesPayment/PaymentVsCfdi.vue";
import PaymentVsCfdiByDate from "../components/FilesPayment/PaymentVsCfdiByDate.vue";

import RetencionesE from "../components/FilesRetenciones/RetencionesEmitidas";
import RetencionesEA from "../components/FilesRetenciones/RetencionesEmitidasAll";
import RetencionesECan from "../components/FilesRetenciones/RetencionesEmitidasCanceladas";
import RetencionesR from "../components/FilesRetenciones/RetencionesRecibidas";
import RetencionesRA from "../components/FilesRetenciones/RetencionesRecibidasAll";
import RetencionesRCan from "../components/FilesRetenciones/RetencionesRecibidasCanceladas";
import RetencionesFechaCancelacion from "../components/FilesRetenciones/RetencionesFechaCancelacion";

import ReportClientes from "../components/Miscelanea/ClientList";
import ReportProveedores from "../components/Miscelanea/VendorsList";
import DeudaReport from "../components/Miscelanea/DeudaReport";
import Cancelados from "../components/Miscelanea/Cancelados";
import DescargaMasivaReport from "../components/Miscelanea/DescargaMasiva";
import Validaciones from "../components/Miscelanea/Validaciones";
import Comparador from "../components/Miscelanea/Comparador";

import Metricas from "../components/Metrics/Metricas";

import MontoImpuestos from "../components/AnalisisImpuestos/MontoImpuestos";
import ImpuestosPagadosCobrados from "../components/AnalisisImpuestos/ImpuestosPagadosCobrados";
import ImpuestosPagadosCobradosREP from "../components/AnalisisImpuestos/ImpuestosPagadosCobradosREP";
import ImpuestosNomina from "../components/AnalisisImpuestos/Nomina";

import DescargaMasiva from "../components/DescargaMasiva/GuardarFiel";
import Conceptos from "../components/FilesConceptos/ConceptosNew";
import ProdServ from "../components/FilesConceptos/ReporteProdServ";

import Login from "../components/Login";
import Perfil from "../components/Perfil";
import Usuarios from "../components/AdministracionUsuarios";
import Contrato from "../components/ContratoServicio";
import Empty from "../components/generics/Background";
import store from "../store/store";



import AuditoriaMoneda from "../components/Auditoria/RepMoneda";
import AuditoriaFormaPago from "../components/Auditoria/RepFormaPago";
import AuditoriaLugarExpedicion from "../components/Auditoria/RepLugarExpedicion";
import AuditoriaUsoCfdi from "../components/Auditoria/RepUsoCfdi";
import AuditoriaRegimenFiscal from "../components/Auditoria/RepRegimenFiscal";


import SustituidosNoCancelados from "../components/Inconsistencias/SustituidosNoCancelados";
import RelacionadosInexistentes from "../components/Inconsistencias/RelacionadosInexistentes";
import NoSustituidosCancelados from "../components/Inconsistencias/NoSustituidosCancelados";

import Duplicados from "../components/FilesConciliatorio/Duplicados";
import RDuplicados from "../components/FilesConciliatorio/PosiblesDuplicados";
import DuplicadosCO from "../components/FilesConciliatorio/DuplicadosCO";
import RDuplicadosCO from "../components/FilesConciliatorio/PosiblesDuplicadosCO";
import Riesgo from "../components/RiesgoFiscal/RiesgoFiscal";


import TestWijmo from "../components/RiesgoFiscal/TestWijmo";


Vue.use(VueRouter);

const routes = [

  {
    path: "/test",
    name: "test",
    component: TestWijmo,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0)){
        next();
      } else next("/");
    }
  },
  {
    path: "/home",
    name: "home",
    component: Empty,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0)){
        next();
      } else next("/");
    }
  },

  
  {
    path: "/conciliatorio/ResumenDuplicados",
    name: "ResumenDuplicados",
    component: RDuplicados,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true) && (store.state.allowIssued === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/conciliatorio/Duplicados",
    name: "Duplicados",
    component: Duplicados,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true) && (store.state.allowIssued === true)){
        next();
      } else next("/");
    }
  },

  {
    path: "/conciliatorio/ResumenDuplicadosCadenaOriginal",
    name: "ResumenDuplicadosCO",
    component: RDuplicadosCO,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true) && (store.state.allowIssued === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/conciliatorio/DuplicadosCadenaOriginal",
    name: "DuplicadosCO",
    component: DuplicadosCO,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true) && (store.state.allowIssued === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/conciliatorio/emitidos/todos",
    name: "conciliatorio-emitidos-todos",
    component: ConciliatorioEA,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true) && (store.state.allowIssued === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/conciliatorio/autofacturas",
    name: "conciliatorio-auto",
    component: ConciliatorioAuto,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true) && (store.state.allowIssued === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/conciliatorio/emitidos",
    name: "conciliatorio-emitidos",
    component: ConciliatorioE,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true) && (store.state.allowIssued === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/conciliatorio/bl",
    name: "conciliatorio-bl",
    component: ConciliatorioBL,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/conciliatorio/resumen",
    name: "conciliatorio-resumen",
    component: ConciliatorioResumen,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/conciliatorio/impuestosLocales",
    name: "impuestosLocales",
    component: ImpuestosLocales,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true)){
        next();
      } else next("/");
    }
  },

  

  {
    path: "/conciliatorio/desgloseColumnas",
    name: "desgloseImpuestosColumnas",
    component: DesgloseImpuestosColumnas,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/conciliatorio/desglose",
    name: "desgloseImpuestos",
    component: DesgloseImpuestos,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/conceptos/general",
    name: "conceptos-general",
    component: Conceptos,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/conceptos/prodserv",
    name: "conceptos-prodserv",
    component: ProdServ,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/conciliatorio/emitidos/cancelados",
    name: "conciliatorio-emitidos-cancelados",
    component: ConciliatorioECan,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true) && (store.state.allowIssued === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/conciliatorio/recibidos/todos",
    name: "conciliatorio-recibidos-todos",
    component: ConciliatorioRA,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true) && (store.state.allowReceived === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/conciliatorio/recibidos",
    name: "conciliatorio-recibidos",
    component: ConciliatorioR,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true) && (store.state.allowReceived === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/conciliatorio/recibidos/cancelados",
    name: "conciliatorio-recibidos-cancelados",
    component: ConciliatorioRCan,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true) && (store.state.allowReceived === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/conciliatorio/cancelados",
    name: "conciliatorio-cancelados",
    component: ConciliatorioCancelados,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/conciliatorio/vigentescancelados",
    name: "vigentes-cancelados",
    component: VigentesCancelados,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true)){
        next();
      } else next("/");
    }
  },
  
  {
    path: "/conciliatorio/fechacancelacion",
    name: "conciliatorio-fechacancelacion",
    component: ConciliatorioPorFechaCancelacion,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true)){
        next();
      } else next("/");
    }
  }

  
  ,
  {
    path: "/reporte/ingresoegreso/emitidos",
    name: "/reporte-ingresoegreso-emitidos",
    component: BreakDownE,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true)){
        next();
      } else next("/");
    }
  }
  ,
  {
    path: "/reporte/ingresoegreso/recibidos",
    name: "reporte-ingresoegreso-recibidos",
    component: BreakDownR,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true) && (store.state.allowReceived === true)){
        next();
      } else next("/");
    }
  }
  ,
  {
    path: "/Nomina/ControlNomina",
    name: "reporte-ControlNomina",
    component: ReporteControlNomina,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isPayroll===true)) {
        next();
      } else next("/");
    }
  }
  ,
  {
    path: "/Nomina/ReporteNomina",
    name: "reporte-Nomina",
    component: ReporteNomina,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isPayroll===true)) {
        next();
      } else next("/");
    }
  },
  {
    path: "/Nomina/ReporteNomina/Cancelados",
    name: "reporte-Nomina-Cancelados",
    component: ReporteNominaCancelados,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isPayroll===true)) {
        next();
      } else next("/");
    }
  },
  {
    path: "/Nomina/ReporteNominaFechaCancelacion",
    name: "reporte-NominaFechaCancelacion",
    component: ReporteNominaFechaCancelacion,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isPayroll===true)) {
        next();
      } else next("/");
    }
  },
  {
    path: "/Nomina/DetalleNomina",
    name: "detalle-Nomina",
    component: DetalleNomina,
    beforeEnter(to, from, next) {
       if ((store.state.token.length > 0) && (store.state.isPayroll === true)) {
        next();
      } else next("/");
    }
  },
  {
    path: "/Nomina/DetalleNominaDesglose",
    name: "detalle-nomina-desglose",
    component: DetalleNominaDesglose,
    beforeEnter(to, from, next) {
       if ((store.state.token.length > 0) && (store.state.isPayroll === true)) {
        next();
      } else next("/");
    }
  },
  {
    path: "/Nomina/DetalleNominaDesglose2",
    name: "detalle-nomina-desglose-2",
    component: DetalleNominaDesgloseV2,
    beforeEnter(to, from, next) {
       if ((store.state.token.length > 0) && (store.state.isPayroll === true)) {
        next();
      } else next("/");
    }
  },
  {
    path: "/Pagos/Extemporaneidad",
    name: "pagos-extemporaneidad",
    component: PaymentEx,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) &&  (store.state.accountType === "FULL")) {
        next();
      } else next("/");
    }
  },
  {
    path: "/Pagos/NoEncontrados",
    name: "pagos-noencontrados",
    component: PaymentNotFound,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) &&  (store.state.accountType === "FULL")) {
        next();
      } else next("/");
    }
  },
  {
    path: "/Pagos/NoPermitidos",
    name: "pagos-nopermitidos",
    component: PaymentNotAllowed,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) &&  (store.state.accountType === "FULL")) {
        next();
      } else next("/");
    }
  },
  {
    path: "/Pagos/RelCancelados",
    name: "pagos-relcancelados",
    component: PaymentRelCancelled,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.accountType === "FULL")) {
        next();
      } else next("/");
    }
  },
  {
    path: "/Financiero/FlujoEfectivo",
    name: "financiero-flujoefectivo",
    component: Cashflow,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) &&  (store.state.accountType === "FULL")) {
        next();
      } else next("/");
    }
  },
  {
    path: "/Relacionados/Todos",
    name: "relacionados-todos",
    component: RelacionadosA,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/Relacionados/Emitidos",
    name: "relacionados-emitidos",
    component: RelacionadosE,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true) && (store.state.allowIssued === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/Relacionados/Recibidos",
    name: "relacionados-recibidos",
    component: RelacionadosR,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true) && (store.state.allowReceived === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/Relacionados/CFDIS",
    name: "relacionados-xray",
    component: RelacionadosX,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true)){
        next();
      } else next("/");
    }
  },
  
  {
    path: "/AgeAnalysis",
    name: "AgeAnalysis",
    component: AgeAnalysis,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) &&  (store.state.accountType === "FULL")) {
        next();
      } else next("/");
    }
  },

  {
    path: "/ConciliatorioIvaSat",
    name: "ConciliatorioIvaSat",
    component: IvaSat,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) &&  (store.state.accountType === "FULL")) {
        next();
      } else next("/");
    }
  },

  
  {
    path: "/Income/Todos",
    name: "income-todos",
    component: IncomeA,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) &&  (store.state.accountType === "FULL")) {
        next();
      } else next("/");
    }
  },
  {
    path: "/Income/Emitidos",
    name: "income-emitidos",
    component: IncomeE,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) &&  (store.state.accountType === "FULL") && (store.state.allowIssued === true)) {
        next();
      } else next("/");
    }
  },
  {
    path: "/Income/Recibidos",
    name: "income-recibidos",
    component: IncomeR,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) &&  (store.state.accountType === "FULL") && (store.state.allowReceived === true)) {
        next();
      } else next("/");
    }
  },
  {
    path: "/ListasNegras/ListaNegra",
    name: "listas-negras",
    component: BlackListReport,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) &&  (store.state.accountType === "FULL")) {
        next();
      } else next("/");
    }
  },
  {
    path: "/ListasNegras/ListaNegraB",
    name: "listas-negrasB",
    component: BlackListBReport,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) &&  (store.state.accountType === "FULL")) {
        next();
      } else next("/");
    }
  },
  {
    path: "/ListasNegras/ListaNegraResumen",
    name: "listas-negras-resumen",
    component: BlackListResume,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) &&  (store.state.accountType === "FULL")) {
        next();
      } else next("/");
    }
  },
  {
    path: "/Inconsistencias/MetodoPago",
    name: "metodo-pago",
    component: PaymentMethodVsForm,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.accountType === "FULL")) {
        next();
      } else next("/");
    }
  },
  {
    path: "/Inconsistencias/PaymentVsCfdi",
    name: "pagos-cfdis",
    component: PaymentVsCfdi,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.accountType === "FULL")) {
        next();
      } else next("/");
    }
  },
  {
    path: "/Inconsistencias/PaymentVsCfdiByDate",
    name: "pagos-cfdis-date",
    component: PaymentVsCfdiByDate,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.accountType === "FULL")) {
        next();
      } else next("/");
    }
  },
 
  {
    path: "/retenciones/emitidas/todas",
    name: "retenciones-emitidas-todas",
    component: RetencionesEA,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true) && (store.state.allowIssued === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/retenciones/emitidas",
    name: "retenciones-emitidas",
    component: RetencionesE,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true) && (store.state.allowIssued === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/retenciones/fechacancelacion",
    name: "retenciones-fechacancelacion",
    component: RetencionesFechaCancelacion,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true)){
        next();
      } else next("/");
    }
  },
 {
    path: "/retenciones/emitidas/canceladas",
    name: "retenciones-emitidas-canceladas",
    component: RetencionesECan,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true) && (store.state.allowIssued === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/retenciones/recibidas/todas",
    name: "retenciones-recibidas-todas",
    component: RetencionesRA,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true) && (store.state.allowReceived === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/retenciones/recibidas",
    name: "retenciones-recibidas",
    component: RetencionesR,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true) && (store.state.allowReceived === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/retenciones/recibidas/canceladas",
    name: "retenciones-recibidas-canceladas",
    component: RetencionesRCan,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true) && (store.state.allowReceived === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/metricas/metricas",
    name: "metricas",
    component: Metricas,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/DesacargaAutomatica/GuardarFIEL",
    name: "descargaAutomatica",
    component: DescargaMasiva,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/Miscelanea/Clientes",
    name: "ReporteClientes",
    component: ReportClientes,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/Miscelanea/Deuda",
    name: "DeudaReport",
    component: DeudaReport,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/Miscelanea/Proveedores",
    name: "ReporteProveedores",
    component: ReportProveedores,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true)){
        next();
      } else next("/");
    }
  },
  {
    path: "/Miscelanea/Cancelados",
    name: "Cancelados",
    component: Cancelados,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.hasFiel === true)) {
        next();
      } else next("/");
    
    }
  },
  {
    path: "/Miscelanea/DescargaMasiva",
    name: "DescargaMasivaReport",
    component: DescargaMasivaReport,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isInvoice === true)) {
        next();
      } else next("/");
    
    }
  },

  
  {
    path: "/Miscelanea/Validaciones",
    name: "Validaciones",
    component: Validaciones,
    beforeEnter(to, from, next) {
      if (store.state.token.length > 0) {
        next();
      } else next("/");
    
    }
  },
  {
    path: "/Miscelanea/Comparador",
    name: "Comparador",
    component: Comparador,
    beforeEnter(to, from, next) {
      if (store.state.token.length > 0) {
        next();
      } else next("/");
    
    }
  },
  {
    path: "/AnalisisImpuestos/MontoImpuestos",
    name: "MontoImpuestos",
    component: MontoImpuestos,
    beforeEnter(to, from, next) {
      if (store.state.token.length > 0) {
        next();
      } else next("/");
    
    }
  },
  {
    path: "/AnalisisImpuestos/ImpuestosPagadosCobrados",
    name: "ImpuestosPagadosCobrados",
    component: ImpuestosPagadosCobrados,
    beforeEnter(to, from, next) {
      if (store.state.token.length > 0) {
        next();
      } else next("/");
    
    }
  },
  {
    path: "/AnalisisImpuestos/ImpuestosPagadosCobradosREP",
    name: "ImpuestosPagadosCobradosREP",
    component: ImpuestosPagadosCobradosREP,
    beforeEnter(to, from, next) {
      if (store.state.token.length > 0) {
        next();
      } else next("/");
    
    }
  },
  {
    path: "/AnalisisImpuestos/Nomina",
    name: "ImpuestosNomina",
    component: ImpuestosNomina,
    beforeEnter(to, from, next) {
      if (store.state.token.length > 0) {
        next();
      } else next("/");
    
    }
  }
  ,
  {
    path: "/Inconsistencias/SustituidosNoCancelados",
    name: "SustituidosNoCancelados",
    component: SustituidosNoCancelados,
    beforeEnter(to, from, next) {
      if (store.state.token.length > 0) {
        next();
      } else next("/");
    
    }
  }
  ,
  {
    path: "/Inconsistencias/RelacionadosInexistentes",
    name: "RelacionadosInexistentes",
    component: RelacionadosInexistentes,
    beforeEnter(to, from, next) {
      if (store.state.token.length > 0) {
        next();
      } else next("/");
    
    }
  }
  ,
  {
    path: "/Inconsistencias/NoSustituidosCancelados",
    name: "NoSustituidosCancelados",
    component: NoSustituidosCancelados,
    beforeEnter(to, from, next) {
      if (store.state.token.length > 0) {
        next();
      } else next("/");
    
    }
  }
  ,
  {
    path: "/Auditoria/Moneda",
    name: "AuditoriaMoneda",
    component: AuditoriaMoneda,
    beforeEnter(to, from, next) {
      if (store.state.token.length > 0) {
        next();
      } else next("/");
    
    }
  },
  {
    path: "/Auditoria/FormaPago",
    name: "AuditoriaFormaPago",
    component: AuditoriaFormaPago,
    beforeEnter(to, from, next) {
      if (store.state.token.length > 0) {
        next();
      } else next("/");
    
    }
  },
  {
    path: "/Auditoria/UsoCFDI",
    name: "AuditoriaUsoCfdi",
    component: AuditoriaUsoCfdi,
    beforeEnter(to, from, next) {
      if (store.state.token.length > 0) {
        next();
      } else next("/");
    
    }
  },
  {
    path: "/Auditoria/RegimenFiscal",
    name: "AuditoriaRegimenFiscal",
    component: AuditoriaRegimenFiscal,
    beforeEnter(to, from, next) {
      if (store.state.token.length > 0) {
        next();
      } else next("/");
    
    }
  },
  {
    path: "/Auditoria/LugarExpedicion",
    name: "AuditoriaLugarExpedicion",
    component: AuditoriaLugarExpedicion,
    beforeEnter(to, from, next) {
      if (store.state.token.length > 0) {
        next();
      } else next("/");
    
    }
  },



  {
    path: "/",
    name: "login",
    component: Login
  }
  ,
  {
    path: "/perfil",
    name: "perfil",
    component: Perfil,
    beforeEnter(to, from, next) {
      if (store.state.token.length > 0) {
       next();
     } else next("/");
   }
  }
  ,
  {
    path: "/ContratoServicios",
    name: "contrato",
    component: Contrato,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isAdministrator === true)) {
       next();
     } else next("/");
   }
  }
  ,
  {
    path: "/usuarios",
    name: "usuarios",
    component: Usuarios,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0) && (store.state.isAdministrator === true)) {
       next();
     } else next("/");
    }
  },
  {
    path: "/riesgo",
    name: "riesgo",
    component: Riesgo,
    beforeEnter(to, from, next) {
      if ((store.state.token.length > 0)) {
       next();
     } else next("/");
    }
  }

];

const router = new VueRouter({
  routes
});

export default router;
